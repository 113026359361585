'use client'
import { FlatButton, RoundedButton } from '@/src/components/Button'
import ImageLang from '@/src/components/ImageLang'
import LocalizedLink from '@/src/components/LocalizedLink'
import styles from '@/src/components/NavBar/Sidemenu/Sidemenu.module.scss'
import WalletButton from '@/src/components/NavBar/Wallet/WalletButton'
import { TotalNotification } from '@/src/components/NotificationIcon'
import SlideMenu from '@/src/components/SlideMenu/SlideMenu'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import useDateFormatter from '@/src/hooks/useDateFormatter'
import { api } from '@/src/services'
import { useGetPaymentActivityQuery } from '@/src/services/player'
import timeFormatter from '@/src/utils/services/timeFormatter'
import IntercomWidget from '@/src/widgets/IntercomWidget'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsSliders } from 'react-icons/bs'
import { IoCloseOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

type SidemenuProps = {
  onClose: () => void
  isOpen: boolean | null
}

const Sidemenu = ({ onClose, isOpen }: SidemenuProps) => {
  const { t } = useTranslation(['common', 'compliance'])
  const { isLoggedIn } = useAuthGuard()
  const [lastLoginTime, setLastLoginTime] = useState<string | undefined>(
    undefined,
  )
  const { data: payment } = useGetPaymentActivityQuery(undefined, {
    skip: !isLoggedIn,
  })
  const { formatter: date } = useDateFormatter({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isLoggedIn) {
      setLastLoginTime(undefined)
      dispatch(api.util.invalidateTags(['PaymentActivity']))
      return
    }

    const loginActivitites = payment?.activities?.filter(
      (activity) => activity.activityCode === 'Login',
    )

    if (!loginActivitites || !loginActivitites[1]) {
      return
    }

    const dateLogin = new Date(loginActivitites[1].createdTime)
    setLastLoginTime(`${date.format(dateLogin)} ${timeFormatter(dateLogin)}`)
  }, [date, payment, isLoggedIn, dispatch])

  return (
    <SlideMenu directions='right' isOpen={isOpen} onClose={onClose}>
      <div className='flex h-full flex-col justify-between'>
        <div className='w-full'>
          <div className='m-3 flex gap-2'>
            <div className='relative flex flex-1 items-center justify-start'>
              <button
                type='button'
                aria-label='close'
                className={'absolute rounded-full bg-white/19 p-1.5 text-sm'}
                onClick={onClose}
              >
                <IoCloseOutline size='1.6rem' />
              </button>
            </div>
            <div className='relative top-2 h-16 w-[88px]'>
              <ImageLang
                alt='lucky-logo'
                fill
                src='/logos/Lucky-casino-trademark@2x.png'
                sizes='88px'
              />
            </div>
            <div className='flex flex-1 items-center justify-end'>
              {isLoggedIn && (
                <LocalizedLink href='/logout'>
                  <FlatButton size='small'>{t('logout')}</FlatButton>
                </LocalizedLink>
              )}
            </div>
          </div>
          <div className='m-6'>
            <div className='p-3 text-center'>
              <WalletButton
                isWalletOpen={false}
                onClick={onClose}
                size='large'
                className='w-full'
                dataAutomationId={
                  isLoggedIn
                    ? 'current-balance-sidebar-id'
                    : 'start-playing-sidebar-id'
                }
              />
            </div>
            {isLoggedIn && (
              <div className='p-3 text-center'>
                <LocalizedLink href={'/my-account'} onClick={onClose}>
                  <RoundedButton
                    type='secondary'
                    className='w-full'
                    icon={
                      <BsSliders size='19px' style={{ alignSelf: 'start' }} />
                    }
                    size='large'
                  >
                    {t('my-account')}
                  </RoundedButton>
                </LocalizedLink>
              </div>
            )}
          </div>
          <ol className='flex flex-col'>
            {isLoggedIn && (
              <>
                <li className='my-3.5 text-center text-xl font-semibold uppercase'>
                  <LocalizedLink
                    href={'/inbox-messages'}
                    className='relative'
                    onClick={onClose}
                  >
                    <div className='flex content-center items-center justify-center'>
                      <div className='relative flex'>
                        {t('inbox-string')}
                        <TotalNotification className='-right-10 top-1.5' />
                      </div>
                    </div>
                  </LocalizedLink>
                </li>
                <li>
                  <div className={cn(styles.cardLine)} />
                </li>
              </>
            )}
            <li className='my-3.5 text-center text-xl font-semibold uppercase'>
              <LocalizedLink href={'/'} onClick={onClose}>
                {t('casino')}
              </LocalizedLink>
            </li>
            <li>
              <div className={cn(styles.cardLine)} />
            </li>
            <li className='my-3.5 text-center text-xl font-semibold uppercase'>
              <LocalizedLink href={'/live'} onClick={onClose}>
                {t('live')}
              </LocalizedLink>
            </li>
            <li>
              <div className={cn(styles.cardLine)} />
            </li>
            <li className='my-3.5 text-center text-xl font-semibold uppercase'>
              <LocalizedLink href={'/sports'} onClick={onClose}>
                {t('sport')}
              </LocalizedLink>
            </li>
            <li>
              <div className={cn(styles.cardLine)} />
            </li>
            <li className='my-3.5 text-center text-xl font-semibold uppercase'>
              <LocalizedLink href={'/casino/jackpot'} onClick={onClose}>
                {t('jackpot')}
              </LocalizedLink>
            </li>
            <li>
              <div className={cn(styles.cardLine)} />
            </li>
            <li>
              <div className={cn(styles.cardLine)} />
            </li>
            <li className='my-3.5 text-center text-xl font-semibold uppercase'>
              <LocalizedLink href={'/promotions'} onClick={onClose}>
                {t('promotions')}
              </LocalizedLink>
            </li>
            <li>
              <div className={cn(styles.cardLine)} />
            </li>
            <IntercomWidget
              className='my-3.5 text-center text-xl font-semibold uppercase'
              skipWrapperStyle={true}
            >
              <div className='my-3.5 flex-1' onClick={onClose}>
                {t('contact-support-sidemenu')}
              </div>
            </IntercomWidget>
          </ol>
        </div>

        {lastLoginTime && isLoggedIn && (
          <div>
            <div className='m-auto my-8 text-center'>
              <p className='text-white/70'>
                {t('compliance:last-login')}
                <span className='ml-1'>{lastLoginTime}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </SlideMenu>
  )
}

export default Sidemenu
